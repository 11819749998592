import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from 'components/Layout'
import Container from 'components/Container'
import Helmet from 'react-helmet'


export default function CrashCoursesPage({ data: { site, allMdx } }) {
  return (
    <Layout site={site} noFooter={false}>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@chrisachard" />
        <meta name="twitter:title" content='Chris Achard' />
        <meta name="twitter:description" content='Teaching everything I know about software: React, JS, Rails, Node.js' />
        <meta name="twitter:image" content='https://chrisachard.com/chris_twitter.png' />
      </Helmet>

      <Container
        css={css`
          padding-bottom: 0;
        `}
      >

        <h1><span role="img" aria-label="fire" aria-labelledby="">🔥</span> Crash Courses</h1>

        <p>
          If you want to learn the basics <i>fast</i>, nothing beats a super targeted crash course.
        </p>
        <p>
          In these courses, I try to distill a complex topic down into just a few, highly targeted lessons, and then I post the whole thing as a twitter thread.
        </p>
        <p>
          Below are the latest ones, and if you want to know when I make more, you can follow me on twitter: <a href="https://twitter.com/chrisachard" target="_blank" rel="noopener noreferrer">@chrisachard</a>
        </p>

        <div style={{
          marginTop: 50,
          marginBottom: 50,
        }}>
          <div css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;
            .crash-course {
              max-width: 300px;
              margin-bottom: 40px;
              border: 1px solid #ddd;
              padding: 10px;
              border-radius: 10px;
              box-shadow: 1px 1px 4px #ddd;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
            .crash-course:hover {
              box-shadow: 2px 2px 4px #bbb;
              background-color: #f5f5f5;
            }
            .crash-course img {
              margin: 0;
              margin-bottom: 10px;
            }
            .crash-course h1 {
              margin: 0;
              font-size: 1.5rem;
            }
            .crash-course:hover {
              text-decoration: none;
            }
            .crash-course:hover .tweet-link {
              text-decoration: underline;
            }
            .crash-course .tweet-link {
              text-align: right;
              font-size: 12px;
              font-style: italic;
            }
            .crash-course:hover h1 {
              text-decoration: underline;
            }
            .crash-course p {
              color: #444;
            }
          `}>

            <a 
              href="https://twitter.com/chrisachard/status/1188870256971915265" 
              className="crash-course"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/crash-courses/dns-crash-course.png" alt="DNS" title="DNS" />
              <div>
                <h1>DNS Records for Web Developers</h1>
                <p>ALIAS, CNAME, and A Records... oh my! <span role="img" aria-label="think">🌐</span></p>
                <div className="tweet-link">10 Tweets →</div>
              </div>
            </a>

            <a 
              href="https://twitter.com/chrisachard/status/1179015849459507200" 
              className="crash-course"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/crash-courses/redux-crash-course.png" alt="React with Hooks" title="React With Hooks" />
              <div>
                <h1>Redux Crash Course (with hooks)</h1>
                <p>Redux is a way to share state across your entire application <span role="img" aria-label="think">🌀</span></p>
                <div className="tweet-link">10 Tweets →</div>
              </div>
            </a>


            <a 
              href="https://twitter.com/chrisachard/status/1175022111758442497" 
              className="crash-course"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/crash-courses/react-with-hooks.png" alt="React with Hooks" title="React With Hooks" />
              <div>
                <h1>Learn React in 10 Tweets (with hooks)</h1>
                <p>Struggling to learn react?  Here's 10 tweets that will get you started.  Yes really! <span role="img" aria-label="think">🦄</span></p>
                <div className="tweet-link">10 Tweets →</div>
              </div>
            </a>



            <a 
              href="https://twitter.com/chrisachard/status/1173750491458789378" 
              className="crash-course"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/crash-courses/map-filter-reduce.png" alt="Map/Filter/Reduce Crash Course" title="Map/Filter/Reduce Crash Course" />
              <div>
                <h1>map/filter/reduce</h1>
                <p>Have you heard about map, filter, and reduce, but never really understood them? <span role="img" aria-label="think">🤔</span></p>
                <div className="tweet-link">10 Tweets →</div>
              </div>
            </a>

            <a 
              href="https://twitter.com/chrisachard/status/1171124289128554498" 
              className="crash-course"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/crash-courses/git.png" alt="Git Crash Course" title="Git Crash Course" />
              <div>
                <h1>Git</h1>
                <p>Do you use git but still don't really understand it? <span role="img" aria-label="shrug">🤷‍♂️</span></p>
                <div className="tweet-link">14 Tweets →</div>
              </div>
            </a>

            <a 
              href="https://twitter.com/chrisachard/status/1167132279333957632" 
              className="crash-course"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/crash-courses/hooks.png" alt="React Hooks Crash Course" title="React Hooks Crash Course" />
              <div>
                <h1>React Hooks</h1>
                <p>Want to learn hooks, but you've been too busy? <span role="img" aria-label="timer">⏲</span></p>
                <div className="tweet-link">10 Tweets →</div>
              </div>
            </a>

            <a 
              href="https://twitter.com/chrisachard/status/1169223691122749440" 
              className="crash-course"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/crash-courses/es6.png" alt="ES6 Crash Course" title="ES6 Crash Course" />
              <div>
                <h1>ES6 Basics</h1>
                <p>Want to write javascript like a sane person?</p>
                <div className="tweet-link">13 Tweets →</div>
              </div>
            </a>



          </div>
        </div>



      </Container>
    </Layout>
  )
}


export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
  }
`
